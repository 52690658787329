@import url(https://use.typekit.net/jrd2dsp.css);
* {
  font-family: 'proxima-nova', sans-serif;
  outline: none;
  box-sizing: border-box;
}

body {
  background: #1e1e1e;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
p,
a,
button,
input,
textarea,
select {
  color: #ffffff;
  margin: 0;
}

.four h3,
h4 {
  color: #212121;
}

.analyse .large h3,
h4 {
  color: #212121;
}

.editor .features>h3,
.editor .features h4 {
  color: #ffffff;
}

.four>h3,
.four h4 {
  color: #ffffff;
}

.analyse .large p {
  color: #737374;
}

.analyse .small p {
  color: #737374;
}

section#tune h2 {
  color: #212121;
}

section#tune p {
  color: #737374;
}

section#tune .tune h3 {
  color: #212121;
}

h1,
h2,
h3 {
  font-weight: 600;
}

h1 {
  font-size: 35px;
  line-height: 130%;
}

h2 {
  font-size: 45px;
  line-height: 140%;
  margin: 5px 0 15px 0;
}

h3 {
  font-size: 17px;
}

p,
a,
ul,
li,
button,
input,
textarea,
select {
  font-size: 17px;
  line-height: 170%;
}

p,
ul,
li {
  color: #D3D3D3;
}

*+p {
  margin-top: 10px;
}

p.sub {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fe6500;
}

a {
  text-decoration: none;
  cursor: pointer;
}

p a {
  color: #fe6500;
}

button,
input,
textarea,
select {
  -webkit-appearance: none;
  border: 0;
}

ul {
  padding-left: 25px;
}

ul li:nth-of-type(n+2) {
  margin-top: 5px;
}

h3 {
  font-size: 30px;
  font-weight: 600;
}

h4 {
  font-size: 17px;
  font-weight: 400;
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }
}

.btn {
  display: block;
  font-size: 15px;
  padding: 8px 20px;
  border-radius: 4px;
  transition: 0.2s;
  text-align: center;
  cursor: pointer;
}

.btn:hover {
  opacity: 0.8;
}

.btn:disabled {
  opacity: 0.3;
  cursor: default;
}

.btnPrimary {
  background: #fe6500;
  border: 1px solid #fe6500;
}

.btnEmpty {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.btnIcon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btnIcon img {
  width: 13px;
  margin-right: 13px;
}

.wide {
  width: calc(100% - 200px);
  margin: 0 auto;
}

.wide hr {
  opacity: 0.1;
  margin: 0;
}

@media screen and (max-width: 1600px) {
  .wide {
    width: 90%;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 90%;
  }
}

section {
  padding: 175px 0 0 0;
}

section>.heading {
  width: 520px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

section>.heading span {
  background: #fe6500;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
}

section>.heading p {
  width: 440px;
  max-width: 100%;
  margin: 20px auto 0 auto;
}

section.dark {
  background: #212121;
}

section.top {
  margin-top: -490px;
  padding-top: 400px;
  padding-bottom: 175px;
}

section.noTop {
  padding: 0 0 130px 0;
}

@media screen and (max-width: 850px) {
  section {
    padding: 100px 0;
  }
}

.feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature>img {
  width: 50%;
}

.feature>div {
  width: 50%;
  display: flex;
  justify-content: center;
}

.feature>div>div {
  width: 385px;
}

.feature:nth-of-type(n+2) {
  margin-top: 130px;
}

@media screen and (max-width: 1100px) {
  .feature {
    flex-direction: column;
  }

  .feature>img,
  .feature>div {
    width: 100%;
    max-width: 650px;
  }

  .feature>img>div,
  .feature>div>div {
    width: 100%;
  }

  .feature>*:last-child {
    margin-top: 60px;
  }

  .feature:nth-of-type(n+2) {
    margin-top: 100px;
  }
}

.appStores {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  margin-top: 15px;
}

.appStores a {
  margin: 0 15px 0 0 !important;
  font-size: 0;
}

.appStores a img {
  height: 25px;
  width: auto;
}

div.subNav {
  background: #292929;
  border-bottom: 1px solid #393939;
}

div.subNav>div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

div.subNav>div p,
div.subNav>div a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0;
  margin-left: 15px;
}

div.subNav>div div.systemStatus {
  display: flex;
  align-items: center;
  position: relative;
}

div.subNav>div div.systemStatus span {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 100px;
}

div.subNav>div div.systemStatus span.green {
  background: #2fca2f;
}

div.subNav>div div.systemStatus span.red {
  background: #f00f00;
}

div.subNav>div div.systemStatus:hover div.days {
  display: grid;
}

@media screen and (max-width: 700px) {
  div.subNav>div div.systemStatus:hover div.days {
    display: none;
  }
}

div.subNav>div div.systemStatus div.days {
  display: none;
  position: absolute;
  background: #292929;
  top: 28px;
  right: -16px;
  padding: 12px 15px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid #393939;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

div.subNav>div div.systemStatus div.days>div {
  display: grid;
  grid-template-columns: 100px auto auto auto;
  grid-gap: 10px;
}

div.subNav>div div.systemStatus div.days p {
  margin: 0;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px;
}

div.subNav>div a.login {
  padding: 8px 15px;
  border-left: 1px solid #393939;
  border-right: 1px solid #393939;
  display: flex;
  align-items: center;
}

div.subNav>div a.login img {
  width: 12px;
  margin-left: 5px;
}

div.subNav>div a.mailto {
  padding: 8px 0px 8px 15px;
  border-left: 1px solid #393939;
}

@media screen and (max-width: 700px) {
  div.subNav>div {
    justify-content: space-between;
  }
}

@media screen and (max-width: 550px) {
  div.subNav>div a.mailto,
  div.subNav>div a.login {
    display: none;
  }

  div.subNav>div div.systemStatus {
    padding: 8px 0;
  }
}

div.nav {
  display: flex;
  justify-content: space-between;
  margin: 35px auto;
}

div.nav>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.nav>div #logo {
  font-size: 0;
}

div.nav>div #logo img {
  width: 225px;
}

div.nav>div nav {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  margin-left: 50px;
}

div.nav>div nav a:nth-of-type(n+5) {
  display: none;
}

div.nav>div nav a.active {
  border-top: 2px solid #fe6500;
  padding-top: 42px;
  margin-top: -44px;
}

div.nav>div nav a span {
  background: #fe6500;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 10px;
  vertical-align: middle;
  margin-left: 5px;
  letter-spacing: 1px;
}

@media screen and (max-width: 1410px) {
  div.nav>div nav {
    grid-column-gap: 20px;
  }
}

@media screen and (max-width: 1250px) {
  div.nav>div nav {
    grid-column-gap: 12px;
  }
}

div.nav>div:nth-of-type(2) a:nth-of-type(1).active {
  border-top: 2px solid #fe6500;
  padding-top: 42px;
  margin-top: -44px;
}

div.nav>div:nth-of-type(2) a:nth-of-type(n+2) {
  margin-left: 50px;
}

@media screen and (max-width: 1250px) {
  div.nav>div:nth-of-type(2) a:nth-of-type(n+2) {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1105px) {
  div.nav>div nav {
    display: none;
  }

  div.nav>div nav.visible {
    position: absolute;
    top: 140px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    left: 0;
    z-index: 100;
  }

  div.nav>div nav.visible a {
    padding: 20px 5%;
    background: #292929;
    display: block !important;
  }

  div.nav>div nav.visible a:nth-of-type(even) {
    background: #212121;
  }

  div.nav>div nav.visible a:last-of-type {
    background: #fe6500;
  }

  div.nav>div nav.visible a.active {
    border-top: none;
    padding-top: 20px;
    margin-top: 0;
  }

  div.nav>div:nth-of-type(2) {
    display: none;
  }
}

#hamburger {
  display: none;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 75px;
  right: 5%;
}

#hamburger span {
  display: block;
  position: absolute;
  top: 50%;
  height: 2px;
  width: 100%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background: #ffffff;
  transition: -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}

#hamburger span::before,
#hamburger span::after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
}

#hamburger span::before {
  bottom: 10px;
  transition: bottom 0.1s ease-out 0.2s, -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in, bottom 0.1s ease-out 0.2s;
  transition: transform 0.1s ease-in, bottom 0.1s ease-out 0.2s, -webkit-transform 0.1s ease-in;
}

#hamburger span::after {
  top: 10px;
  transition: background 0.01s ease 0.1s, top 0.1s ease-out 0.2s;
}

#hamburger.close span {
  transform: rotate(45deg) translate(-1px, -1px);
  -o-transform: rotate(45deg) translate(-1px, -1px);
  -ms-transform: rotate(45deg) translate(-1px, -1px);
  -moz-transform: rotate(45deg) translate(-1px, -1px);
  -webkit-transform: rotate(45deg) translate(-1px, -1px);
  transition: -webkit-transform 0.1s ease-out 0.2s;
  transition: transform 0.1s ease-out 0.2s;
  transition: transform 0.1s ease-out 0.2s, -webkit-transform 0.1s ease-out 0.2s;
}

#hamburger.close span:after {
  top: 0;
  background: none;
  transition: top 0.1s ease-in, background 0.01s ease-out 0.1s;
}

#hamburger.close span::before {
  bottom: 0;
  transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transition: bottom 0.1s ease-in, -webkit-transform 0.1s ease-out 0.2s;
  transition: bottom 0.1s ease-in, transform 0.1s ease-out 0.2s;
  transition: bottom 0.1s ease-in, transform 0.1s ease-out 0.2s, -webkit-transform 0.1s ease-out 0.2s;
}

@media screen and (max-width: 1105px) {
  #hamburger {
    display: block;
  }
}

footer {
  padding: 0 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

footer p,
footer a,
footer input {
  font-size: 15px;
}

footer div.links {
  display: flex;
}

footer div.links div {
  display: flex;
  flex-direction: column;
}

footer div.links>div:nth-of-type(n+2) {
  margin-left: 80px;
}

footer div.links>div>div:nth-of-type(2) {
  margin-top: 15px;
}

footer div.links a:first-of-type {
  margin-top: 10px;
}

footer div.links a:nth-of-type(n+2) {
  margin-top: 5px;
}

footer div.newsletter {
  flex-direction: column;
  max-width: 450px;
}

footer div.newsletter .msg-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #151515;
  border-radius: 3px;
}

footer div.newsletter .msg-alert p {
  padding: 15px;
}

footer div.newsletter a {
  margin-top: 10px;
  width: 200px;
}

footer div.newsletter form {
  display: flex;
  margin: 10px 0 5px 0;
}

footer div.newsletter form input[type=email],
footer div.newsletter form button {
  padding: 12px 15px;
}

footer div.newsletter form input[type=email] {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
  color: #000000;
}

footer div.newsletter form button {
  border: none;
  -webkit-appearance: none;
  background: #fe6500;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media screen and (max-width: 1300px) {
  footer {
    flex-direction: column;
  }

  footer div.links,
  footer div.newsletter {
    width: 100%;
    max-width: 100%;
  }

  footer div.links div:nth-of-type(n+2),
  footer div.newsletter div:nth-of-type(n+2) {
    margin-top: 20px;
  }

  footer div.newsletter {
    margin-top: 60px;
  }
}

@media screen and (max-width: 850px) {
  footer {
    padding: 0 0 80px 0;
  }
}

@media screen and (max-width: 700px) {
  footer div.links {
    flex-direction: column;
  }

  footer div.links>div:nth-of-type(2) {
    margin-top: 15px;
    margin-left: 0;
  }

  footer div.links>div:nth-of-type(n+2) {
    margin-left: 0;
  }
}

header {
  padding: 100px 0 150px 0;
}

header .appStores {
  justify-content: center;
}

header.short {
  padding: 100px 0;
}

header .narrow {
  width: 600px;
  max-width: 100%;
}

header.hero {
  display: flex;
  align-items: center;
  height: 800px;
}

header.hero>img {
  position: absolute;
  height: 1200px;
  right: 0;
  z-index: -5;
  opacity: 0.9;
}

header.hero div.content {
  width: 600px;
  max-width: 100%;
  z-index: 2;
}

header.hero div.content h1 {
  font-size: 70px;
  line-height: 130%;
}

header.hero div.content div {
  display: flex;
  align-items: center;
}

header.hero div.content div>*:last-child {
  margin-left: 15px;
}

header.hero div.content .btn:last-of-type {
  margin-top: 20px;
  display: inline-block;
}

@media screen and (max-width: 900px) {
  header.hero div.content div>*:last-child {
    background: #292929;
  }
}

@media screen and (max-width: 650px) {
  header.hero div.content h1 {
    font-size: 50px !important;
  }

  header.hero div.content div {
    flex-direction: column;
    align-items: flex-start;
  }

  header.hero div.content div>*:last-child {
    margin-left: 0px;
  }
}

@media screen and (max-width: 400px) {
  header.hero div.content h1 {
    font-size: 40px !important;
  }

  header.hero div.content p {
    font-size: 15px !important;
  }
}

header.hero div.video {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
}

header.hero div.video span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  z-index: 1;
}

header.hero div.video iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50vw;
  height: 28.125vw;
  z-index: 2;
}

@media screen and (max-width: 1200px) {
  header.hero div.video iframe {
    width: 75vw;
    height: 42.188vw;
  }
}

@media screen and (max-width: 1200px) {
  header.hero div.video iframe {
    width: 95vw;
    height: 53.452vw;
  }
}

header.hero div.graphic {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: calc(50% + 150px);
  height: 870px;
  z-index: -1;
}

header.hero div.graphic img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 120%;
}

@media screen and (max-width: 650px) {
  header.hero {
    padding-bottom: 50px;
  }
}

header.featurePage>div {
  text-align: center;
}

header.featurePage>div>span {
  display: inline-block;
  color: #fe6500;
  padding: 5px 10px;
  border: 1px solid #fe6500;
  border-radius: 3px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

header.featurePage>div h1 {
  margin-top: 20px;
}

header.featurePage>img {
  width: 100%;
  margin-top: 100px;
}

header.featurePage>img.shadow {
  box-shadow: 0 10px 25px 0 rgba(0,0,0,0.75);
}

header.featurePage.howItWorks {
  padding-bottom: 0;
}

header.featurePage.howItWorks>img {
  width: 600px;
  margin: 50px auto 0 auto;
  display: block;
  max-width: 100%;
}

@media screen and (max-width: 850px) {
  header {
    padding: 50px 0 100px 0;
  }

  header.short {
    padding: 60px 0;
  }

  header.hero {
    height: 600px;
  }
}

@media screen and (max-width: 600px) {
  header.hero {
    height: 500px;
  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.popup>div {
  width: 1000px;
  height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  background: #212121;
  color: #ffffff;
  padding: 60px;
  overflow: hidden;
}

.popup>div h2 {
  font-size: 24px;
}

.popup>div div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 400px;
}

.popup>div div .btn {
  display: inline-block;
  margin-top: 30px;
}

.popup>div img:nth-of-type(1) {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 15px;
  cursor: pointer;
}

.popup>div img:nth-of-type(2) {
  position: absolute;
  height: 800px;
  top: -150px;
  right: -400px;
  z-index: -1;
}

@media screen and (max-width: 1100px) {
  .popup>div {
    width: 90%;
  }

  .popup>div img:nth-of-type(2) {
    opacity: 0.2;
  }
}

@media screen and (max-width: 600px) {
  .popup>div {
    height: auto;
    padding: 40px;
  }

  .popup>div>div {
    position: relative;
    width: 100%;
    top: 0;
    -webkit-transform: none;
            transform: none;
  }

  .popup>div img:nth-of-type(2) {
    display: none;
  }
}

.popup span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
  z-index: 1;
}

.video-react {
  position: absolute;
  top: 150px;
  z-index: -1;
  padding: 0 !important;
  width: 100% !important;
  height: 800px;
  overflow: hidden;
  background: #212121;
}

.video-react video {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  height: auto;
}

.video-react video.height {
  width: auto;
  height: 100%;
}

.video-react button,
.video-react .video-react-control-bar {
  display: none;
}

@media screen and (max-width: 850px) {
  .video-react {
    height: 600px;
  }
}

@media screen and (max-width: 600px) {
  .video-react {
    height: 500px;
  }
}

.manufacturerLogos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.manufacturerLogos img {
  width: 110px;
}

@media screen and (max-width: 700px) {
  .manufacturerLogos img {
    width: 90px;
  }
}

.onePlatform {
  padding: 150px 0 0 0;
  overflow: hidden;
}

.onePlatform .container {
  position: relative;
  z-index: 2;
}

.onePlatform .container>div>h2 {
  width: 400px;
  max-width: 100%;
}

.onePlatform .container>div>div {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.onePlatform .container>div>div a {
  background: #292929;
  padding: 35px;
  border-radius: 3px;
  transition: 0.2s;
}

.onePlatform .container>div>div a img {
  height: 30px;
}

.onePlatform .container>div>div a h2 {
  font-size: 22px;
  margin: 15px 0 0 0;
}

.onePlatform .container>div>div a p {
  margin: 5px 0 0 0;
}

.onePlatform .container>div>div a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1100px) {
  .onePlatform .container>div>div {
    grid-template-columns: 1fr;
  }
}

.onePlatformImage {
  width: 100%;
  margin-top: 120px;
  overflow: hidden;
}

.onePlatformImage img {
  width: 1500px;
  margin-left: calc(50% - 750px);
}

.improveYourWorkflow {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}

.improveYourWorkflow img {
  width: 35px;
  height: 35px;
  -o-object-fit: contain;
     object-fit: contain;
}

.improveYourWorkflow h3 {
  margin: 25px 0 15px 0;
}

@media screen and (max-width: 1200px) {
  .improveYourWorkflow {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 750px) {
  .improveYourWorkflow {
    grid-template-columns: 1fr;
  }
}

.builtForScale {
  position: relative;
  display: flex;
  align-items: center;
  height: 600px;
}

.builtForScale>.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.builtForScale>.container>img {
  height: 600px;
  width: auto;
  display: block;
}

.builtForScale>.container>div {
  width: calc(50% - 100px);
}

.builtForScale>.container>div>div {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.builtForScale>.container>div>div>div p {
  margin: 0;
}

.builtForScale>.container>div>div>div p:nth-of-type(1) {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

.builtForScale>.container>div>div>div p:nth-of-type(2) {
  margin-top: 5px;
}

@media screen and (max-width: 1100px) {
  .builtForScale {
    padding: 0;
    height: auto;
  }

  .builtForScale .container {
    flex-direction: column;
  }

  .builtForScale .container>div {
    width: 100%;
    margin-top: 60px;
  }
}

@media screen and (max-width: 700px) {
  .builtForScale .container>img {
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .builtForScale .container {
    overflow: hidden;
  }

  .builtForScale .container>img {
    height: auto;
    width: 175%;
  }

  .builtForScale .container>div>div {
    grid-template-columns: 1fr;
  }
}

.ourProducts {
  padding-top: 0;
}

.ourProducts .products {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.ourProducts .product {
  display: grid;
  grid-template-rows: 50px auto 1fr auto;
  grid-row-gap: 20px;
  width: 300px;
}

.ourProducts .product img {
  align-self: center;
  max-height: 100%;
  width: 150px;
}

.ourProducts .product img.pcw,
.ourProducts .product img.sm {
  width: 50px;
}

.ourProducts .product p {
  margin: 0;
}

@media screen and (max-width: 1300px) {
  .ourProducts .product {
    width: 250px;
  }
}

@media screen and (max-width: 1100px) {
  .ourProducts h2 {
    text-align: center;
  }

  .ourProducts .products {
    flex-direction: column;
  }

  .ourProducts .product {
    justify-items: center;
    text-align: center;
    width: 350px;
    margin: 40px auto;
    grid-template-rows: 70px auto 1fr auto;
  }

  .ourProducts .product img {
    width: 190px;
  }

  .ourProducts .product img.pcw,
  .ourProducts .product img.sm {
    width: 100px;
    margin-bottom: 20px;
  }
}

.hero.container .content>div.rolodex {
  margin-top: 10px;
  height: 46px;
  position: relative;
}

.hero.container .content>div.rolodex div {
  position: absolute;
  top: 46px;
  transition: 0.5s;
  opacity: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.hero.container .content>div.rolodex div img {
  height: 22px;
}

.hero.container .content>div.rolodex div p {
  font-size: 28px;
  color: #ffffff;
  margin: 0 0 0 10px;
}

.hero.container .content>div.rolodex div.active {
  top: 0;
  opacity: 1;
}

.hero.container .content>div.rolodex div.post {
  top: -46px;
}

@media screen and (max-width: 600px) {
  .hero.container .content>div.rolodex div img {
    height: 20px;
  }

  .hero.container .content>div.rolodex div p {
    font-size: 25px;
  }

  .hero.container .content>div.buttons {
    margin-top: 15px;
    display: inline-block;
  }

  .hero.container .content>div.buttons a {
    margin-top: 15px;
  }
}

.three {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.three a {
  background: #212121;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
}

.three a>div {
  position: relative;
  height: 230px;
}

.three a>div p {
  width: calc(100% - 90px);
  position: absolute;
  font-size: 21px;
  text-align: center;
  color: #9e9e9e;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.three a>div p span {
  color: #ffffff;
}

@media screen and (max-width: 1000px) {
  .three {
    grid-template-columns: 1fr;
  }

  .three>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .three>div>div {
    height: auto;
  }
}

@media screen and (max-width: 700px) {
  .three>div {
    grid-template-columns: 1fr;
  }

  .three>div>div>p {
    position: relative;
    padding: 50px 0;
  }
}

.analyse .large {
  margin-top: 75px;
  background: #ffffff;
  border-radius: 20px;
  font-size: 0;
  overflow: hidden;
}

.analyse .large>div {
  padding: 45px;
}

.analyse .small {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
}

.analyse .small>div {
  background: #ffffff;
  border-radius: 15px;
  padding: 45px;
}

.analyse .small>div p {
  margin-top: 6px;
}

@media screen and (max-width: 1200px) {
  .analyse .small {
    grid-template-columns: 1fr;
  }
}

.narrow {
  width: 600px;
  max-width: 100%;
}

@media screen and (max-width: 1100px) {
  .narrow {
    width: 100%;
  }
}

.light {
  position: absolute;
  background: #efefef;
  width: 100%;
  height: 1700px;
  top: 2760px;
  z-index: -1;
}

@media screen and (max-width: 1300px) {
  .light {
    top: 3100px;
    height: 1800px;
  }
}

@media screen and (max-width: 1100px) {
  .light {
    top: 3000px;
    height: 2400px;
  }
}

@media screen and (max-width: 1000px) {
  .light {
    top: 4200px;
    height: 2400px;
  }
}

@media screen and (max-width: 950px) {
  .light {
    top: 4000px;
    height: 2400px;
  }
}

@media screen and (max-width: 900px) {
  .light {
    top: 3900px;
    height: 2300px;
  }
}

@media screen and (max-width: 850px) {
  .light {
    top: 3800px;
    height: 2300px;
  }
}

@media screen and (max-width: 800px) {
  .light {
    top: 3500px;
    height: 2300px;
  }
}

@media screen and (max-width: 700px) {
  .light {
    top: 4200px;
    height: 2200px;
  }
}

@media screen and (max-width: 600px) {
  .light {
    top: 4000px;
    height: 2300px;
  }
}

@media screen and (max-width: 500px) {
  .light {
    top: 3700px;
    height: 2400px;
  }
}

@media screen and (max-width: 376px) {
  .light {
    top: 3800px;
    height: 2800px;
  }
}

.tune {
  margin-top: 50px;
  display: grid;
  grid-template-columns: auto 450px;
  grid-gap: 30px;
}

.tune>div {
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tune>div>div {
  padding: 45px;
}

.tune>div .addons {
  margin-top: 20px;
  flex-wrap: wrap;
}

.tune>div .addons p {
  margin: 0 0 15px 0;
  background: #f4f4f4;
  font-size: 15px;
  padding: 6px 10px;
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .tune {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 1100px) {
  .tune {
    grid-template-columns: 1fr;
  }
}

.editor>img {
  width: 100%;
  margin-top: 50px;
}

.editor .features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.editor .features>div {
  background: #212121;
  border-radius: 10px;
  padding: 30px;
}

.editor .features>div img {
  height: 25px;
  margin-bottom: 15px;
}

.editor .features>div p {
  margin-top: 6px;
}

@media screen and (max-width: 1200px) {
  .editor .features {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .editor .features {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .editor .features {
    grid-template-columns: 1fr;
  }
}

.four {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}

.four>div {
  border: 1px solid #393939;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.four>div img {
  height: 90px;
  margin-top: 15px;
}

.four>div>div {
  margin-top: 40px;
}

.four>div>div p {
  margin-top: 6px;
}

@media screen and (max-width: 1200px) {
  .four {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .four {
    grid-template-columns: 1fr;
  }

  .four>div {
    align-items: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .four>div {
    align-items: center;
    text-align: center;
  }

  .four>div img {
    height: 70px;
  }
}

.automated {
  margin-top: -125px;
  padding-top: 220px;
  padding-bottom: 120px;
}

.switch {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.switch>div {
  width: calc(100% / 3);
  border-top: 2px solid #444444;
  padding: 30px 30px;
  margin: 0;
  text-align: left;
}

.switch>div p {
  opacity: 0.5;
}

.switch>div.active {
  border-top: 2px solid #ffffff;
}

.switch>div.active p {
  opacity: 1;
}

@media screen and (max-width: 1300px) {
  .switch {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .switch {
    flex-direction: column;
  }

  .switch>div {
    width: 100%;
    border-top: 0;
    border-left: 2px solid #444444;
  }

  .switch>div.active {
    border-top: none;
    border-left: 2px solid #ffffff;
  }
}

.appStores {
  justify-content: left;
}

.appStores p {
  margin: 0 15px 0 0;
}

.feature.diagnostics {
  justify-content: center;
}

.feature.diagnostics>div {
  width: 40%;
}

.feature.diagnostics>img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 600px;
  width: auto;
}

.feature.diagnostics:nth-of-type(n+2) {
  margin-top: 60px;
}

@media screen and (max-width: 1100px) {
  .feature.diagnostics>div {
    width: 100%;
  }

  .feature.diagnostics>img {
    height: auto;
    width: 100%;
    max-width: 500px;
  }
}

.desktopViewer {
  padding: 100px 0 0 0;
}

.desktopViewer>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktopViewer>div>img {
  margin-top: 50px;
  width: 90%;
}

@media screen and (max-width: 1000px) {
  .desktopViewer>div>img {
    width: 100%;
  }
}

.desktopViewer>div>div:nth-of-type(1) {
  text-align: center;
}

.desktopViewer>div>div:nth-of-type(2) {
  width: 80%;
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}

.desktopViewer>div>div:nth-of-type(2) img {
  width: 35px;
  height: 35px;
  -o-object-fit: contain;
     object-fit: contain;
}

.desktopViewer>div>div:nth-of-type(2) h3 {
  margin: 25px 0 15px 0;
}

@media screen and (max-width: 1200px) {
  .desktopViewer>div>div:nth-of-type(2) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .desktopViewer>div>div:nth-of-type(2) {
    grid-template-columns: 1fr;
  }
}

.limitedOffer {
  transition: 0.2s;
  top: -100px;
  position: fixed;
  background: #292929;
  text-align: center;
  padding: 6px 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #393939;
}

.limitedOffer p {
  color: #ffffff;
  font-size: 14px;
}

.limitedOffer p span {
  letter-spacing: 1px;
}

.limitedOffer:hover {
  background: #363636;
}

.limitedOffer.show {
  top: 0;
}

.plans {
  display: grid;
  grid-template-columns: 375px;
  grid-gap: 50px;
  justify-content: center;
}

.plans .plan {
  position: relative;
  padding: 40px 40px 25px 40px;
  background: #292929;
  text-align: left;
  border-radius: 4px;
  max-width: 100%;
}

.plans .plan>span {
  position: absolute;
  top: -9px;
  background: #fe6500;
  color: #ffffff;
  border-radius: 3px;
  font-size: 12px;
  padding: 3px 8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.plans .plan h2 {
  margin: 0;
  font-size: 24px;
}

.plans .plan .price {
  font-size: 21px;
}

.plans .plan .price span {
  font-size: 17px;
}

.plans .plan>div {
  margin-top: 20px;
}

.plans .plan>div p {
  margin: 0;
  display: flex;
  align-items: center;
}

.plans .plan>div p img {
  width: 18px;
  margin-right: 8px;
}

.plans .plan>div p img.cross {
  width: 13px;
  margin-right: 13px;
}

.plans .plan .btn {
  margin-top: 30px;
  width: 100%;
}

.plans .plan .smallPrint {
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .plans {
    width: 100%;
    max-width: 100% !important;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 850px) {
  .plans {
    grid-template-columns: 1fr;
  }
}

.centred {
  margin-top: 40px;
  text-align: center;
}

.faqs {
  margin-bottom: 150px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}

.faqs>div p {
  border-left: 4px solid #222222;
  padding-left: 20px;
  font-size: 15px;
  color: #D3D3D3;
}

@media screen and (max-width: 1300px) {
  .faqs {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .faqs {
    grid-template-columns: 1fr;
  }
}

.stats {
  margin-top: 50px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;
}

.stats>div {
  background: #212121;
  border-radius: 10px;
  padding: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats>div h2,
.stats>div p {
  margin: 0;
}

.stats>div p {
  border-left: 4px solid #fe6500;
  margin-left: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 1100px) {
  .stats {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .stats>div {
    justify-content: flex-start;
    padding: 0;
    background: none;
  }
}

.compatibilityChecker {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.compatibilityChecker>div:not(.fileDrop) {
  width: 575px;
  z-index: 2;
}

.compatibilityChecker>div.fileDrop {
  position: relative;
  background: #212121;
  height: 375px;
  width: calc(100% - 400px);
  margin-left: -175px;
  z-index: 1;
}

.compatibilityChecker>div.fileDrop>label,
.compatibilityChecker>div.fileDrop>div {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compatibilityChecker>div.fileDrop>label button,
.compatibilityChecker>div.fileDrop>div button {
  margin-top: 15px;
  width: 150px;
}

@media screen and (max-width: 900px) {
  .compatibilityChecker {
    flex-direction: column;
  }

  .compatibilityChecker>div:not(.fileDrop),
  .compatibilityChecker>div.fileDrop {
    width: 100%;
    margin: 0;
  }

  .compatibilityChecker>div.fileDrop {
    margin-top: 40px;
    height: 140px;
  }
}

.protocolSearch {
  margin-top: 60px;
}

.protocolSearch .loading {
  text-align: center;
  padding: 100px 0;
}

.protocolSearch .switch {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
}

.protocolSearch .switch p {
  text-align: center;
  border: 1px solid #393939;
  margin: 0;
  padding: 10px;
  background: #212121;
}

.protocolSearch .switch p:nth-of-type(1) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.protocolSearch .switch p:nth-of-type(2) {
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.protocolSearch .switch p.active {
  background: #292929;
}

.protocolSearch .form {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-areas: "left right" "searchTerm searchTerm";
}

.protocolSearch .form select {
  background: #1e1e1e;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid #3B3B3B;
  width: 100%;
  cursor: pointer;
  background: #212121;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 10px;
}

.protocolSearch .form select:first-of-type {
  grid-area: left;
}

.protocolSearch .form select:last-of-type {
  grid-area: right;
}

.protocolSearch .form select:disabled {
  cursor: default;
  opacity: 0.5;
}

.protocolSearch .form .searchTerm {
  grid-area: searchTerm;
  border-radius: 4px;
  border: 1px solid #3B3B3B;
  background-color: #212121;
  padding: 10px 15px;
  box-sizing: border-box;
}

.protocolSearch .results {
  margin-bottom: 30px;
  border: 1px solid #4c4c4c;
}

.protocolSearch .results div {
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 1.5fr 2fr 3fr 2fr 2.5fr;
  grid-gap: 15px;
}

.protocolSearch .results div p {
  margin-top: 0 !important;
  display: flex;
  align-items: center;
}

.protocolSearch .results div p img {
  width: 12px;
  margin-right: 7px;
}

.protocolSearch .results div:nth-child(odd):not(.head) {
  background: #212121;
}

.protocolSearch .results div.head {
  background: #292929;
  border-bottom: 1px solid #4c4c4c;
}

.protocolSearch .results div.head p {
  font-weight: 600;
}

.protocolSearch .results.ecu div {
  grid-template-columns: 2fr 1.5fr 2fr 3fr 2.5fr;
}

.protocolSearch .pagination {
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .protocolSearch .results div {
    grid-template-columns: 1.5fr 2fr 3fr 2fr;
    grid-gap: 5px;
  }

  .protocolSearch .results div p:nth-of-type(4) {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .protocolSearch .switch {
    grid-template-columns: 1fr;
  }

  .protocolSearch .switch p:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  .protocolSearch .switch p:nth-of-type(2) {
    border-left: 1px solid #393939;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  .protocolSearch .results div {
    grid-template-columns: 1.5fr 2fr 3fr;
  }

  .protocolSearch .results div p:nth-of-type(5) {
    display: none;
  }
}

.articles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.articles>a img {
  width: 100%;
  height: 240px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.articles>a h3 {
  margin-top: 20px;
  font-size: 24px;
  line-height: 130%;
}

.articles>a p:last-of-type {
  margin-top: 20px;
  border-top: 1px solid #393939;
  padding-top: 15px;
  font-size: 15px;
}

@media screen and (max-width: 1100px) {
  .articles {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .articles {
    grid-template-columns: 1fr;
  }
}

.article {
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
}

.article header {
  padding: 100px 0 80px 0;
}

.article header h1 {
  font-size: 50px;
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  .article header h1 {
    font-size: 40px;
  }
}

.article header p.subtitle {
  font-size: 25px;
  opacity: 0.7;
}

.article header p.tag,
.article header p.date {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.article header p.tag {
  color: #fe6500;
}

.article header p.date {
  border-top: 1px solid #393939;
  padding-top: 25px;
  margin-top: 20px;
}

.article figure {
  margin: 0;
  max-width: 100%;
  font-size: 0;
}

.article figure img,
.article figure iframe {
  width: 100%;
}

.article figure iframe {
  height: 450px;
  margin-bottom: 20px;
}

.article figure figcaption {
  background: rgba(255,255,255,0.05);
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  opacity: 0.7;
}

.article h2 {
  font-size: 32px;
  margin-top: 40px;
}

.article h3 {
  font-size: 28px;
  margin-top: 30px;
}

.latestUpdateLink {
  overflow: hidden;
  margin-bottom: 20px;
}

.latestUpdateLink>a {
  background: #232425;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 4px;
  justify-content: space-between;
  box-sizing: border-box;
}

.latestUpdateLink>a>div {
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  width: calc(100% - 20px);
}

.latestUpdateLink>a>div p {
  color: #ffffff;
  margin: 0;
  font-size: 15px;
  flex-shrink: 0;
}

.latestUpdateLink>a>div p:first-of-type {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  opacity: 0.5;
  line-height: 100%;
}

.latestUpdateLink>a>div p:last-of-type {
  margin-left: 15px;
  max-width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latestUpdateLink>a img {
  width: 16px;
  flex: 0 1;
}

.callToAction>a {
  position: relative;
  margin-top: 175px;
  background: #fe6500;
  background: linear-gradient(120deg, #fe6500 0%, #fc4c0e 100%);
  padding: 120px;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.callToAction>a>img {
  width: 80px;
}

.callToAction>a>div {
  max-width: 100%;
}

.callToAction>a>div h1 {
  width: 600px;
  max-width: 100%;
  font-size: 70px;
}

.callToAction>a>div .ticks {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.callToAction>a>div .ticks>div {
  display: flex;
  align-items: center;
}

.callToAction>a>div .ticks>div img {
  height: 16px;
}

.callToAction>a>div .ticks>div p {
  margin: 0 0 0 10px;
  color: #ffffff;
}

.callToAction>a>div .ticks>div:nth-of-type(n+2) {
  margin-left: 35px;
}

@media screen and (max-width: 1100px) {
  .callToAction>a {
    padding: 80px;
    flex-direction: column;
    align-items: flex-start;
  }

  .callToAction>a>div h1 {
    font-size: 60px;
    width: 500px;
    max-width: 100%;
  }

  .callToAction>a>div .ticks {
    flex-direction: column;
    align-items: flex-start;
  }

  .callToAction>a>div .ticks>div {
    width: 100%;
  }

  .callToAction>a>div .ticks>div:nth-of-type(n+2) {
    margin-top: 10px;
    margin-left: 0;
  }

  .callToAction>a>img {
    margin-top: 40px;
    width: 60px;
  }
}

@media screen and (max-width: 700px) {
  .callToAction>a {
    padding: 60px;
  }
}

@media screen and (max-width: 500px) {
  .callToAction>a {
    padding: 40px;
    border-radius: 20px;
  }

  .callToAction>a>div h1 {
    font-size: 40px;
  }
}

.legal section {
  padding: 20px 0;
}

.legal section:last-of-type {
  padding: 20px 0 60px 0;
}

.legal h2 {
  font-size: 20px;
}

.legal h3 {
  margin-top: 20px;
}

.legal p>a {
  color: #ffffff;
}

.cta {
  margin: 100px auto 150px auto;
}

.cta>div {
  max-width: 450px;
  text-align: center;
  align-items: center;
  margin: 60px auto 0 auto;
}

.masterTools {
  margin-top: 40px;
}

.masterTools h2 {
  text-align: center;
}

.tools {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 190px;
}

.tools a {
  max-width: calc(100% / 6 - 40px);
  font-size: 0;
  transition: 0.2s;
}

.tools a img {
  max-width: 100%;
  max-height: 40px;
}

.tools a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1000px) {
  .tools a {
    width: calc(100% / 3 - 40px);
    max-width: none;
    text-align: center;
  }

  .tools a:nth-of-type(n+4) {
    margin-top: 40px;
  }
}

@media screen and (max-width: 650px) {
  .tools a {
    width: calc(100% / 2 - 40px);
  }

  .tools a:nth-of-type(n+3) {
    margin-top: 40px;
  }
}

.tools-home {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tools-home a {
  max-width: calc(100% / 6 - 40px);
  font-size: 0;
  transition: 0.2s;
}

.tools-home a img {
  max-width: 100%;
  max-height: 40px;
}

.tools-home a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1000px) {
  .tools-home a {
    width: calc(100% / 3 - 40px);
    max-width: none;
    text-align: center;
  }

  .tools-home a:nth-of-type(n+4) {
    margin-top: 40px;
  }
}

@media screen and (max-width: 650px) {
  .tools-home a {
    width: calc(100% / 2 - 40px);
  }

  .tools-home a:nth-of-type(n+3) {
    margin-top: 40px;
  }
}


